<template>
  <div>
    <br />
    <h1>Permissions grant for Social-Wonder Facebook Application</h1>
    <br />
    <h3>Hi {{ clientInfo && clientInfo.name }},</h3>
    <br />
    <h3 v-if="!permission_granted">
      Please grant permissions to Social-Wonder Facebook Application. <br />
      It is needed in order to manage your Facebook page and Instagram
      accounts:<br />
    </h3>
    <br />
    <!-- <v-form ref="form" lazy-validation> -->
    <v-text-field
      v-model="filter_page_id"
      placeholder="1234567890..."
      label="Page ID"
    ></v-text-field>
    <br />

    <!-- <v-card :disabled="valid != true"> -->
    <Facebook
      v-if="!permission_granted"
      :version="version"
      :appId="appId"
      :loginOptions="loginOptions"
      :loginLabel="loginLabel"
      :logoutLabel="logoutLabel"
      :cookie="false"
      :xfbml="false"
      @login="login($event)"
      @logout="logout($event)"
      ref="facebookRef"
    />
    <h3 v-else>Thanks! Permission are succesfully granted.</h3>
    <!-- </v-card> -->
    <!-- </v-form> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Facebook from "@/components/authentication/Facebook";
// import validationRules from "@/shared/validationRules";
//import LogRocket from "logrocket";

export default {
  name: "facebook-login",
  components: {
    Facebook,
  },
  // mixins: [validationRules],

  data() {
    return {
      // valid: false,
      appId: process.env.VUE_APP_FACEBOOK_APP_ID,
      version: "v12.0",
      loginOptions: {
        //https://developers.facebook.com/docs/permissions/reference
        scope:
          "public_profile,email,instagram_manage_insights,pages_show_list,read_insights,pages_read_engagement,instagram_basic,instagram_content_publish,pages_manage_posts",
      },
      loginLabel: "Grant permissions to Social-Wonder Facebook Application",
      logoutLabel: "Log out from Social-Wonder Facebook Application",
      clientInfo: null,
      customerId: null,
      permission_granted: false,
      filter_page_id: null,
    };
  },
  async created() {
    // LogRocket.track("CustomerFacebookAuthentiationAccessed");
    let isValidUser = false;
    if (this.$route.query.id) {
      isValidUser = await this.isValidCustomer(this.$route.query.id);
      this.$refs.facebookRef.logout();
    }
    if (!isValidUser) {
      this.$router.push({
        name: "home",
      });
    }
    this.customerId = this.$route.query.id;
  },
  // watch: {
  //   filter_page_id() {
  //     this.valid = this.filter_page_id && this.$refs.form.validate();
  //   },
  // },
  methods: {
    ...mapActions("clientSelection", ["fetchCustomerInfo", "updateCustomer"]),
    login(response) {
      // LogRocket.track("CustomerFacebookAuthentiationLogin");
      if (!response) return;
      let facebook_token = {
        access_token: response.response.authResponse.accessToken,
        data_access_expiration_time:
          response.response.authResponse.data_access_expiration_time,
        expires_in: response.response.authResponse.expiresIn,
        graph_domain: response.response.authResponse.graphDomain,
        user_id: response.response.authResponse.userID,
        filter_page_id: this.filter_page_id,
      };

      this.updateCustomer({
        customerId: this.clientInfo.id,
        params: {
          facebook_token,
        },
      });
      this.permission_granted = true;
    },
    logout(response) {
      //LogRocket.track("CustomerFacebookAuthentiationLogout");
    },
    async isValidCustomer(customerId) {
      this.clientInfo = await this.fetchCustomerInfo(customerId);
      return this.clientInfo.name != "";
    },
  },
};
</script>
